
import "./Home.css";
import Steps from "./Steps";
import Info from "./Info";
import NextInfo from "./NextInfo";
import NavBar from "./NavBar";
import Info3 from "./Info3";
import Bio from "./Bio";
import { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import { Box, Typography } from "@mui/material";
import SecondBio from "./SecondBio";
import Map from "./Map";
import Reviews from "./Reviews";
import PictureStep from "./PictureStep";
import Background from "../assets/background.jpg"
import skyline from "../assets/skylinelg.jpg";
// import Steps from "./Steps";
import ReactGA from "react-ga4";

// import NewSteps from "./NewSteps";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.default',
    outline: 'none',

    // backgroundImage: `url(${picture})`,
    p: 4,
};

let Home = ({ }) => {
    const [showDialog, setShowDialog] = useState(false);
    useEffect(() => {
        ReactGA.initialize("G-4Z6QXMEDMD");
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowDialog(true);
        }, 60000); // 30 seconds

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    const handleClick = () => {
        ReactGA.event({ category: "link-click", action: "link-click" });
        window.location.href = "https://modusrealestate.com/apartment-locating-sign-up";
    }
    return (
        <div>
            <NavBar />
            <div className="mx-aut">
                <div className="font-poppins static flex flex-col">
                    <div className=" flex items-center max-h-screen-lg h-screen justify-center bg-cover bg-center relative">

                        <img class="-z-10 w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover" src={Background} />
                        <div class="z-1 bg-black bg-opacity-40 h-full w-full flex items-center justify-center">
                            <div class="py-4 md:container flex flex-col items-center justify-center h-full w-full">
                                <div class="px-2 md:px-4">
                                    <h1 class="text-[23px] md:text-5xl uppercase !leading-normal tracking-[4px] text-center text-white my-0 font-aptos">Modus Apartment Locators</h1>
                                    <h2 class="text-[11px] md:text-base text-white text-center uppercase mt-3 mb-2 md:my-4 tracking-[3px]">REDEFINING THE INDUSTRY STANDARD</h2>
                                </div>
                                <div class="flex flex-col mt-7 space-y-3 items-center justify-center sm:flex-row sm:space-y-0 sm:space-x-10">
                                    <a><button onClick={() => handleClick()} type="button" class="font-heading uppercase outline-none focus:outline-none ease-linear transition-all duration-150 font-heading tracking-widest border text-white bg-transparent border-white px-6 py-2  hover:border-white hover:text-global-dark text-center text-[11px] tracking-wider !px-4 !py-0 h-10 md:h-14 md:text-sm whitespace-nowrap border-2">
                                        <span class="mr-1 font-poppins ">START YOUR FREE SEARCH</span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="inline h-6 w-6 -mt-1">
                                            <polyline class="stroke-current" fill="none" stroke-width="1.03" points="7 4 13 10 7 16">
                                            </polyline>
                                        </svg>
                                    </button>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div >
                </div >
                <hr class="max-w-screen-lg mx-auto mb-7" />

                <h2 class="uppercase text-3xl md:text-4xl font-light text-global-dark mx-auto text-center leading-snug md:leading-snug tracking-wider">
                    <span className="">
                        Servicing the Denver, Colorado area
                    </span>
                </h2>

                <Steps />
                {/* <NewSteps /> */}
                <hr class="max-w-screen-lg mx-auto mb-7" />
                <Info />
                <hr class="max-w-screen-lg mx-auto mb-7" />
                <Info3 />
                <hr class="max-w-screen-lg mx-auto mb-7" />
                <NextInfo />
                <hr class="max-w-screen-lg mx-auto mb-7" />
                {/* <Contact /> */}
                <Bio />
                <hr class="max-w-screen-lg mx-auto mb-7" />


                <SecondBio />
                <hr class="max-w-screen-lg mx-auto mb-7" />
                <PictureStep />
                <hr class="max-w-screen-lg mx-auto mb-7" />

                <Map />
                <hr class="max-w-screen-lg mx-auto mb-7" />
                <Reviews />

            </div>

            <Modal
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: darken the backdrop
                    },
                    '& .MuiPaper-root': {
                        outline: 'none', // Remove the blue border
                    },
                }}
            >
                <Box sx={style} className="items-center">
                    <img class="-z-10 w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover" src={skyline} alt="Modus Home Page" />
                    {/* <img class="-z-10 w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover" src={newLogo} alt="Modus Home Page" /> */}

                    {/* <div className=" flex items-center max-h-screen-lg h-screen justify-center bg-cover bg-center relative"> */}


                    {/* <Typography id="modal-modal-description" sx={{ mt: 0 }}> */}
                    <div class="bg-opacity-40 flex flex-col mt-7 space-y-3 w-full h-full items-center justify-center sm:flex-row sm:space-y-0 sm:space-x-10">
                        <a ><button onClick={() => handleClick()} type="button" class="font-heading uppercase outline-none focus:outline-none ease-linear transition-all duration-150 font-heading tracking-widest border text-black  bg-transparent border-black px-6 py-2 hover:bg-white hover:border-black hover:text-global-dark text-center text-[18px] tracking-wider !px-4 !py-0 h-10 md:h-14 md:text-sm whitespace-nowrap border-2">
                            <span class="mr-1 font-poppins ">START YOUR FREE SEARCH</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="inline h-6 w-6 -mt-1">
                                <polyline class="stroke-current" fill="none" stroke-width="1.03" points="7 4 13 10 7 16">
                                </polyline>
                            </svg>
                        </button>
                        </a>
                    </div>
                    {/* </Typography> */}
                    {/* </div> */}
                </Box>
            </Modal>
            {/* 
            {showDialog && (
                <div className="dialog">
                    <img class="-z-10 w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover" src={background} alt="Modus Home Page" />

                   
                </div>
            )} */}
        </div>

    );
}

export default Home;
